<template>
    <w-toolbar-menu
        :title="item.title"
        :items="menu"
        height="52"
        divider
        underlined
        outlined
    />
</template>

<script>
export default {
    name: 'DepartmentMenu',
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    computed: {
        menu() {
            return [
                {
                    text: this.$trans('Teachers'),
                    icon: 'TEACHER',
                    to: {
                        name: 'departments.teachers',
                        params: { uuid: this.item.uuid }
                    }
                },
                {
                    text: this.$trans('Courses'),
                    icon: 'COURSE',
                    to: {
                        name: 'departments.courses',
                        params: { uuid: this.item.uuid }
                    }
                },
                {
                    text: this.$trans('Capacity'),
                    icon: 'CAPACITY',
                    to: {
                        name: 'departments.capacities',
                        params: { uuid: this.item.uuid }
                    }
                }
            ]
        }
    }
}
</script>

<style lang="scss">

</style>
