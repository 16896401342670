<template>
    <content-wrapper
        v-if="department"
        :title="title"
        :comback="{ name: 'departments.index' }"
    >
        <v-row no-gutters>
            <v-col
                cols="12"
                class="mb-4"
            >
                <department-menu
                    :item="department"
                />
            </v-col>
            <v-col cols="12">
                <router-view></router-view>
            </v-col>
        </v-row>
    </content-wrapper>
</template>

<script>
import ContentWrapper from '@/components/ContentWrapper'
import DepartmentMenu from '@/apps/school/components/Department/DepartmentMenu'

export default {
    name: 'DepartmentsShow',
    components: {
        DepartmentMenu,
        ContentWrapper,
    },
    computed: {
        department() {
            return this.$store.getters['school/department'](this.$route.params.uuid)
        },
        title() {
            return this.department.title
        },
        breadcrumbs() {
            return [
                {
                    text: this.$trans('Departments'),
                    to: { name: 'departments' }
                }
            ]
        }
    }
}
</script>

<style lang="scss">

</style>
